<template>
    <section id="bannerHeadService" class="bg--img--banner--service">
        <v-img
            dark
            :src="require('@/assets/Backgrounds/bg-services-fresado.jpg')"
            class="white--text "
        >
        </v-img>
    </section>
</template>

<script>
export default {
    name: 'SectionServicesHeader',
    data() {
        return {
          
        }
    },
}
</script>

<style lang="scss">
    
</style>